<template>
    <v-navigation-drawer
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      app
      right
      height="100%"
      class="background"
    >
      <template v-slot:prepend v-if="isLoggedIn">
        <v-list-item two-line>

          <v-list-item-content>
            <v-list-item-title>{{ getFullName }}</v-list-item-title>
            <v-list-item-subtitle>{{ getEmail }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="page in pages"
          :key="page.name"
          link
          :to="page.route"
          :href="page.external"
          :target="page.target"
        >
          <v-list-item-icon>
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ page.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>




        <!-- <v-list-group
          v-if="isAdmin"
          prepend-icon="mdi-account-circle"
        >
          <template v-slot:activator>
            <v-list-item-title>Admin</v-list-item-title>
          </template>


          <v-list-item
            v-for="page in adminPages"
            :key="page.name"
            link
            :to="page.route"
          >
            <v-list-item-icon>
              <v-icon>{{ page.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ page.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
        </v-list-group> -->



      </v-list>
      
      <template v-slot:append>
        <v-btn 
          block 
          class="ma-2"
          v-on:click="logout"
          outlined
          color="primary"
        >
          Logout
        </v-btn>
      </template>
    </v-navigation-drawer>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
              pages: [
                {
                  name: "Dashboard",
                  icon: "mdi-view-dashboard",
                  route: "/dashboard",
                },
                {
                  name: "Account",
                  icon: "mdi-account",
                  route: "/account",
                }
              ]/* ,
              adminPages: [
                {
                  name: "User Management",
                  icon: "mdi-account-box-multiple",
                  route: "/user"
                }
              ] */
            }
        },

        props: {
            value: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            logout(){
                localStorage.removeItem('token');
                this.$store.dispatch('logout');
                this.$router.push('Login');
            } 
        },

        computed:{
            ...mapGetters([
                'getEmail',
                'isLoggedIn',
                'getFullName',
                'isAdmin'
            ])
        },
    }
</script>

<style lang="scss" scoped>

</style>