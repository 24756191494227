<template>
    <section>
        <v-row>
            <v-col
                cols="12"
                lg="4"
                md="4"
                sm="4"
                xs="12"
                v-for="plan in getAllPublicPlans"
                :key="plan.planID"
            >
                <v-card
                    dark
                    height="100%"
                    :disabled="!plan.active"
                    :class="{ 'current': isCurrentPlan(plan.planID), 'text-center': true, }"
                    color="#2F2F2F"
                >
                    <v-card-subtitle>
                        {{ plan.planName }}
                    </v-card-subtitle>
                    <v-card-title class="justify-center" color="primary">
                        ${{ plan.monthlyPrice / 100 }} <sub>/month</sub>
                    </v-card-title>

                    <v-divider ></v-divider>

                    <v-card-text>
                        Up to {{plan.maxSessions}} simulateous Sessions <br>
                        Up to {{plan.maxClients}} Clients per session
                    </v-card-text>
                    <v-card-actions
                        class="text-center"
                    >
                        <v-spacer></v-spacer>
                        <v-btn 
                            color="primary"
                            outlined
                            v-if="getUserPlan != plan.planID"
                            @click="selectPlan(plan.planID)"
                        >
                            Select
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>6

<script>
    import {mapGetters} from 'vuex';

    export default {
        data() {
            return {
                selectedPlan: 0,
            }
        },
        computed: {
            ...mapGetters([
                'getAllPublicPlans',
                'getUserPlan',
            ]),

            getPlanById(id){
                return plan => this.getAllPublicPlans.filter(plan =>{
                    return plan.planID === id
                });
            }
        },

        methods: {
            isCurrentPlan(id){
                if(this.getUserPlan == id){
                    return true;
                } else {
                    return false;
                }
            },

            selectPlan(id){
                this.$emit('click', id)
            },
        },
    }
</script>

<style scoped>
.current{
    border: 6px solid #FFBA00 !important;
}
</style>