import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueClipboard from 'vue-clipboard2';
//import VStripeElements from 'v-stripe-elements/lib';

import store from './store';


//Vue.use(VStripeElements);
Vue.use(VueClipboard);

Vue.config.productionTip = false

Vue.prototype.$backend = 'https://api.presenter.tools'

new Vue({
  store,
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
