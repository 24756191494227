<template>
    <v-card
        elevation="2"
        class="mx-auto my-6"
        color="background_light"
        max-width="674"
    >
        <v-card-title>
            Login
        </v-card-title>

        
        <v-form @submit.prevent="login">

            <v-card-text>

                <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="this.errorMessage"
                >
                    {{this.errorMessage}}
                </v-alert>

                <v-text-field
                    v-model="user.email"
                    label="E-mail"
                    required
                    type="email"
                    color="primary"
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="user.password"
                    type="password"
                    label="Password"
                    required
                    color="primary"
                    outlined
                ></v-text-field>
                    
            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>

                <!-- <v-btn
                    to="signup"
                    color="primary"
                    text
                >
                    Create an Account
                </v-btn> -->

                <v-btn
                    color="primary"
                    type="submit"
                    outlined
                    :loading="logginIn"
                >
                    Login
                </v-btn>

            </v-card-actions>

        </v-form>
    </v-card>
</template>

<script>
    import Joi from 'joi';

    const schema = Joi.object({
        email: Joi.string().pattern(new RegExp('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5})$')).required(),
        password: Joi.string().trim().min(8).required()
    });

    export default {
        data() {
            return {
                logginIn: false,
                errorMessage: '',
                user: {
                    email: '',
                    password: ''
                }
            }
        },
        methods: {

            /*
            * sends user data to server, receives jwt and sets store
            */
            async login() {

                //reset error message
                this.errorMessage = "";

                //if submitted data is valid
                if(this.validUser()){

                    //create body for request
                    const body = {
                        email: this.user.email,
                        password: this.user.password
                    };
                    
                    //set current state to logginIn
                    this.logginIn = true;

                    //send data to server and await response
                    const fetchResult = await fetch(process.env.VUE_APP_BACKEND_SERVER + '/auth/login', {
                        method: "POST",
                        body: JSON.stringify(body),
                        headers: {
                            'content-type': 'application/json',
                        }

                    //after response was received
                    }).then((res) => {

                        //if request was ok
                        if(res.ok) {

                            //convert response to json and return it
                            return res.json();
                        }

                        //if there was an error
                        return res.json().then((error) => {

                            //foreward error to catch
                            throw new Error(error.message);
                        });

                    //after conversion to json
                    }).then((res) => {

                        //set token in localStorage to received jwt
                        localStorage.token = res.token;

                        //call store action LOGIN (puts received user data into store)
                        this.$store.dispatch('login', [res.user, true]);

                        //after 1 second
                        setTimeout(() => {

                            //redirect to Dashboard
                            this.$router.push({ name: 'Dashboard' });
                        }, 1000)
                    
                    //if error was thrown
                    }).catch((error) => {
                        console.log("Catching error", error);
                        //wait 1 second
                        setTimeout(() => {

                            //set state logginIn to false
                            this.logginIn = false;

                            //and display error message in alert box
                            this.errorMessage = "Unable to login";
                        }, 1000)
                    })
                }
            },

            /*
            * validates if the submitted data is processable
            */
            validUser() {

                //validate submitted data
                const result = schema.validate(this.user);

                //if there was no error
                if(!result.error){

                    //return true
                    return true;
                }

                //if error message includes the word EMAIL
                if(result.error.message.includes('email')){

                    //the user didnt enter a valid email address
                    this.errorMessage = "Please enter a valid E-Mail adress";

                //else the user didnt enter a valid password
                } else {
                    this.errorMessage = "The Password must be at least 8 characters long";
                }
                return false;
            }
        }
    }
</script>

<style scoped>

</style>