<template>
    <section>
        <h1 class="
                mb-md-10
                mb-sm-10
                mb-xs-100"
        >User Management</h1>
        
        <!-- <div class="mb-5"></div> -->

        <v-card 
            class="background_light mx-auto my-6"
            max-width="1000"
        >
            
            <v-card-title>Search User</v-card-title>
                <v-form @submit.prevent="searchUser">

                    <v-card-text>
                            <v-text-field
                                outlined
                                label="Enter Email"
                                v-model="searchUserText"
                            ></v-text-field>

                            
                    </v-card-text>

                    <v-card-actions>

                        <v-spacer></v-spacer>

                        <v-btn
                            outlined
                            color="primary"
                            type="submit"
                            :loading="searchingUser"
                        >Search</v-btn>
                        
                    </v-card-actions>

                </v-form>
        </v-card>

        <div class="mb-10"></div>

        <AllUser v-if="!user" :users="users" @click="selectUser"></AllUser>

        <User v-if="user" :user="user" @close="close"></User>

    </section>
</template>

<script>
    import User from "../../components/User.vue";
    import AllUser from "../../components/AllUser.vue";


    export default {
        name: "UserManagement",

        data() {
            return {
                searchUserText: "",
                searchingUser: false,
                user: "",
                users: []
            }
        },


        methods: {
            async searchUser() {

                this.searchingUser = true;
                this.close();

                if(!this.searchUserText) return;


                fetch(process.env.VUE_APP_BACKEND_SERVER + "/admin/user/email", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": "Bearer " + localStorage.token
                    },
                    body: JSON.stringify({ email: this.searchUserText })
                }).then((res) => {

                    if(res.ok){
                        return res.json();
                    }


                    return res.json().then((error) => {

                        //foreward error to catch
                        throw new Error(error.message);
                    });


                }).then((res) => {
                    
                    this.user = res.user;
                    this.searchUserText = "";
                    this.searchingUser = false;

                });

            },

            selectUser(user){
                console.log("object");
                this.searchUserText = user;
                this.searchUser();
            },

            close(){
                this.user = "";
            }
        },

        mounted() {

            fetch(process.env.VUE_APP_BACKEND_SERVER + "/admin/user/email", {
                headers: {
                    "authorization": "Bearer " + localStorage.token
                }
            }).then((res) => {

                if(res.ok){
                    return res.json();
                }


                return res.json().then((error) => {

                    //foreward error to catch
                    throw new Error(error.message);
                });

            }).then((res) => {
                    
                this.users = res.allUser

            });


        },


        components: {
            User,
            AllUser
        }

    }
</script>

<style scoped>

</style>