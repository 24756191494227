<template>
    <section>
        <h1>Plan Management</h1>

        <v-card 
            class="background_light mx-auto my-6"
            max-width="1000"
        >
            <v-card-title>Plans</v-card-title>

        </v-card>
    </section>
</template>

<script>
    import AllPlans from '../../components/AllPlans';

    export default {
        name: "PlanManagement",

        data() {
            return {
                plans: []
            }
        },

        methods: {
            /* async loadPlans */
        },

        components: {
            AllPlans
        }
    }
</script>

<style lang="scss" scoped>

</style>