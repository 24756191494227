const codes = [ 
  { country: 'Andorra', code: 'AD' },
  { country: 'United Arab Emirates', code: 'AE' },
  { country: 'Afghanistan', code: 'AF' },
  { country: 'Antigua and Barbuda', code: 'AG' },
  { country: 'Albania', code: 'AL' },
  { country: 'Armenia', code: 'AM' },
  { country: 'Angola', code: 'AO' },
  { country: 'Antarctica', code: 'AQ' },
  { country: 'Argentina', code: 'AR' },
  { country: 'American Samoa', code: 'AS' },
  { country: 'Austria', code: 'AT' },
  { country: 'Australia', code: 'AU' },
  { country: 'Aruba', code: 'AW' },
  { country: 'Åland Islands', code: 'AX' },
  { country: 'Azerbaijan', code: 'AZ' },
  { country: 'Bosnia and Herzegovina', code: 'BA' },
  { country: 'Barbados', code: 'BB' },
  { country: 'Bangladesh', code: 'BD' },
  { country: 'Belgium', code: 'BE' },
  { country: 'Burkina Faso', code: 'BF' },
  { country: 'Bulgaria', code: 'BG' },
  { country: 'Bahrain', code: 'BH' },
  { country: 'Burundi', code: 'BI' },
  { country: 'Benin', code: 'BJ' },
  { country: 'Saint Barthélemy', code: 'BL' },
  { country: 'Bermuda', code: 'BM' },
  { country: 'Brunei Darussalam', code: 'BN' },
  { country: 'Bolivia (Plurinational State of)', code: 'BO' },
  { country: 'Brazil', code: 'BR' },
  { country: 'Bahamas', code: 'BS' },
  { country: 'Bhutan', code: 'BT' },
  { country: 'Bouvet Island', code: 'BV' },
  { country: 'Botswana', code: 'BW' },
  { country: 'Belarus', code: 'BY' },
  { country: 'Belize', code: 'BZ' },
  { country: 'Canada', code: 'CA' },
  { country: 'Cocos (Keeling) Islands', code: 'CC' },
  { country: 'Congo, Democratic Republic of the', code: 'CD' },
  { country: 'Central African Republic', code: 'CF' },
  { country: 'Congo', code: 'CG' },
  { country: 'Switzerland', code: 'CH' },
  { country: 'Côte d\'Ivoire', code: 'CI' },
  { country: 'Cook Islands', code: 'CK' },
  { country: 'Chile', code: 'CL' },
  { country: 'Cameroon', code: 'CM' },
  { country: 'China', code: 'CN' },
  { country: 'Colombia', code: 'CO' },
  { country: 'Costa Rica', code: 'CR' },
  { country: 'Cuba', code: 'CU' },
  { country: 'Cabo Verde', code: 'CV' },
  { country: 'Curaçao', code: 'CW' },
  { country: 'Christmas Island', code: 'CX' },
  { country: 'Cyprus', code: 'CY' },
  { country: 'Czechia', code: 'CZ' },
  { country: 'Germany', code: 'DE' },
  { country: 'Djibouti', code: 'DJ' },
  { country: 'Denmark', code: 'DK' },
  { country: 'Dominica', code: 'DM' },
  { country: 'Dominican Republic', code: 'DO' },
  { country: 'Algeria', code: 'DZ' },
  { country: 'Ecuador', code: 'EC' },
  { country: 'Estonia', code: 'EE' },
  { country: 'Egypt', code: 'EG' },
  { country: 'Western Sahara', code: 'EH' },
  { country: 'Eritrea', code: 'ER' },
  { country: 'Spain', code: 'ES' },
  { country: 'Ethiopia', code: 'ET' },
  { country: 'Finland', code: 'FI' },
  { country: 'Fiji', code: 'FJ' },
  { country: 'Falkland Islands (Malvinas)', code: 'FK' },
  { country: 'Micronesia (Federated States of)', code: 'FM' },
  { country: 'Faroe Islands', code: 'FO' },
  { country: 'France', code: 'FR' },
  { country: 'Gabon', code: 'GA' },
  { country: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GB' },
  { country: 'Grenada', code: 'GD' },
  { country: 'French Guiana', code: 'GF' },
  { country: 'Guernsey', code: 'GG' },
  { country: 'Ghana', code: 'GH' },
  { country: 'Gibraltar', code: 'GI' },
  { country: 'Greenland', code: 'GL' },
  { country: 'Gambia', code: 'GM' },
  { country: 'Guinea', code: 'GN' },
  { country: 'Guadeloupe', code: 'GP' },
  { country: 'Equatorial Guinea', code: 'GQ' },
  { country: 'Greece', code: 'GR' },
  { country: 'South Georgia and the South Sandwich Islands',
    code: 'GS' },
  { country: 'Guatemala', code: 'GT' },
  { country: 'Guam', code: 'GU' },
  { country: 'Guinea-Bissau', code: 'GW' },
  { country: 'Guyana', code: 'GY' },
  { country: 'Hong Kong', code: 'HK' },
  { country: 'Heard Island and McDonald Islands', code: 'HM' },
  { country: 'Honduras', code: 'HN' },
  { country: 'Croatia', code: 'HR' },
  { country: 'Haiti', code: 'HT' },
  { country: 'Hungary', code: 'HU' },
  { country: 'Indonesia', code: 'ID' },
  { country: 'Ireland', code: 'IE' },
  { country: 'Israel', code: 'IL' },
  { country: 'Isle of Man', code: 'IM' },
  { country: 'India', code: 'IN' },
  { country: 'British Indian Ocean Territory', code: 'IO' },
  { country: 'Iraq', code: 'IQ' },
  { country: 'Iran (Islamic Republic of)', code: 'IR' },
  { country: 'Iceland', code: 'IS' },
  { country: 'Italy', code: 'IT' },
  { country: 'Jersey', code: 'JE' },
  { country: 'Jamaica', code: 'JM' },
  { country: 'Jordan', code: 'JO' },
  { country: 'Japan', code: 'JP' },
  { country: 'Kenya', code: 'KE' },
  { country: 'Kyrgyzstan', code: 'KG' },
  { country: 'Cambodia', code: 'KH' },
  { country: 'Kiribati', code: 'KI' },
  { country: 'Comoros', code: 'KM' },
  { country: 'Saint Kitts and Nevis', code: 'KN' },
  { country: 'Korea (Democratic People\'s Republic of)',
    code: 'KP' },
  { country: 'Korea, Republic of', code: 'KR' },
  { country: 'Kuwait', code: 'KW' },
  { country: 'Cayman Islands', code: 'KY' },
  { country: 'Kazakhstan', code: 'KZ' },
  { country: 'Lao People\'s Democratic Republic', code: 'LA' },
  { country: 'Lebanon', code: 'LB' },
  { country: 'Saint Lucia', code: 'LC' },
  { country: 'Liechtenstein', code: 'LI' },
  { country: 'Sri Lanka', code: 'LK' },
  { country: 'Liberia', code: 'LR' },
  { country: 'Lesotho', code: 'LS' },
  { country: 'Luxembourg', code: 'LU' },
  { country: 'Latvia', code: 'LV' },
  { country: 'Libya', code: 'LY' },
  { country: 'Morocco', code: 'MA' },
  { country: 'Monaco', code: 'MC' },
  { country: 'Moldova, Republic of', code: 'MD' },
  { country: 'Saint Martin (French part)', code: 'MF' },
  { country: 'Madagascar', code: 'MG' },
  { country: 'Marshall Islands', code: 'MH' },
  { country: 'North Macedonia', code: 'MK' },
  { country: 'Mali', code: 'ML' },
  { country: 'Myanmar', code: 'MM' },
  { country: 'Mongolia', code: 'MN' },
  { country: 'Macao', code: 'MO' },
  { country: 'Northern Mariana Islands', code: 'MP' },
  { country: 'Martinique', code: 'MQ' },
  { country: 'Mauritania', code: 'MR' },
  { country: 'Montserrat', code: 'MS' },
  { country: 'Malta', code: 'MT' },
  { country: 'Mauritius', code: 'MU' },
  { country: 'Maldives', code: 'MV' },
  { country: 'Malawi', code: 'MW' },
  { country: 'Mexico', code: 'MX' },
  { country: 'Malaysia', code: 'MY' },
  { country: 'Mozambique', code: 'MZ' },
  { country: 'Namibia', code: 'NA' },
  { country: 'New Caledonia', code: 'NC' },
  { country: 'Niger', code: 'NE' },
  { country: 'Norfolk Island', code: 'NF' },
  { country: 'Nigeria', code: 'NG' },
  { country: 'Nicaragua', code: 'NI' },
  { country: 'Netherlands', code: 'NL' },
  { country: 'Norway', code: 'NO' },
  { country: 'Nepal', code: 'NP' },
  { country: 'Nauru', code: 'NR' },
  { country: 'Niue', code: 'NU' },
  { country: 'New Zealand', code: 'NZ' },
  { country: 'Oman', code: 'OM' },
  { country: 'Panama', code: 'PA' },
  { country: 'Peru', code: 'PE' },
  { country: 'French Polynesia', code: 'PF' },
  { country: 'Papua New Guinea', code: 'PG' },
  { country: 'Philippines', code: 'PH' },
  { country: 'Pakistan', code: 'PK' },
  { country: 'Poland', code: 'PL' },
  { country: 'Saint Pierre and Miquelon', code: 'PM' },
  { country: 'Pitcairn', code: 'PN' },
  { country: 'Puerto Rico', code: 'PR' },
  { country: 'Palestine, State of', code: 'PS' },
  { country: 'Portugal', code: 'PT' },
  { country: 'Palau', code: 'PW' },
  { country: 'Paraguay', code: 'PY' },
  { country: 'Qatar', code: 'QA' },
  { country: 'Réunion', code: 'RE' },
  { country: 'Romania', code: 'RO' },
  { country: 'Serbia', code: 'RS' },
  { country: 'Rwanda', code: 'RW' },
  { country: 'Saudi Arabia', code: 'SA' },
  { country: 'Solomon Islands', code: 'SB' },
  { country: 'Seychelles', code: 'SC' },
  { country: 'Sudan', code: 'SD' },
  { country: 'Sweden', code: 'SE' },
  { country: 'Singapore', code: 'SG' },
  { country: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH' },
  { country: 'Slovenia', code: 'SI' },
  { country: 'Svalbard and Jan Mayen', code: 'SJ' },
  { country: 'Sierra Leone', code: 'SL' },
  { country: 'San Marino', code: 'SM' },
  { country: 'Senegal', code: 'SN' },
  { country: 'Somalia', code: 'SO' },
  { country: 'Suriname', code: 'SR' },
  { country: 'South Sudan', code: 'SS' },
  { country: 'Sao Tome and Principe', code: 'ST' },
  { country: 'El Salvador', code: 'SV' },
  { country: 'Sint Maarten (Dutch part)', code: 'SX' },
  { country: 'Syrian Arab Republic', code: 'SY' },
  { country: 'Eswatini', code: 'SZ' },
  { country: 'Turks and Caicos Islands', code: 'TC' },
  { country: 'Chad', code: 'TD' },
  { country: 'French Southern Territories', code: 'TF' },
  { country: 'Togo', code: 'TG' },
  { country: 'Thailand', code: 'TH' },
  { country: 'Tajikistan', code: 'TJ' },
  { country: 'Tokelau', code: 'TK' },
  { country: 'Timor-Leste', code: 'TL' },
  { country: 'Turkmenistan', code: 'TM' },
  { country: 'Tunisia', code: 'TN' },
  { country: 'Tonga', code: 'TO' },
  { country: 'Turkey', code: 'TR' },
  { country: 'Trinidad and Tobago', code: 'TT' },
  { country: 'Tuvalu', code: 'TV' },
  { country: 'Taiwan, Province of China', code: 'TW' },
  { country: 'Tanzania, United Republic of', code: 'TZ' },
  { country: 'Ukraine', code: 'UA' },
  { country: 'Uganda', code: 'UG' },
  { country: 'United States Minor Outlying Islands', code: 'UM' },
  { country: 'United States of America', code: 'US' },
  { country: 'Uruguay', code: 'UY' },
  { country: 'Uzbekistan', code: 'UZ' },
  { country: 'Holy See', code: 'VA' },
  { country: 'Saint Vincent and the Grenadines', code: 'VC' },
  { country: 'Venezuela (Bolivarian Republic of)', code: 'VE' },
  { country: 'Virgin Islands (British)', code: 'VG' },
  { country: 'Virgin Islands (U.S.)', code: 'VI' },
  { country: 'Viet Nam', code: 'VN' },
  { country: 'Vanuatu', code: 'VU' },
  { country: 'Wallis and Futuna', code: 'WF' },
  { country: 'Samoa', code: 'WS' },
  { country: 'Yemen', code: 'YE' },
  { country: 'Mayotte', code: 'YT' },
  { country: 'South Africa', code: 'ZA' },
  { country: 'Zambia', code: 'ZM' },
  { country: 'Zimbabwe', code: 'ZW' } 
];

export default codes;