export const address = {
    state: {
        line1: "",
        city: "",
        postal_code: "",
        country: "",
        state: "",
    },

    mutations:{
        setLine1(state, line1){
            state.line1 = line1;
        },
        setCity(state, city){
            state.city = city;
        },
        setPostalCode(state, pc){
            state.postal_code = pc;
        },
        setCountry(state, country){
            state.country = country;
        },
        setState(state, s){
            state.state = s;
        }
    },

    getters: {
        getLine1(state){
            return state.line1;
        },
        getCity(state){
            return state.city;
        },
        getPostalCode(state){
            return state.postal_code;
        },
        getCountry(state){
            return state.country;
        },
        getState(state){
            return state.state;
        },
        getAddress(state){
            return state;
        }
    },

    actions: {
        setAddress(state, adr){
            this.commit('setLine1', adr.line1);
            this.commit('setCity', adr.city);
            this.commit('setPostalCode', adr.postal_code);
            this.commit('setCountry', adr.country);
            this.commit('setState', adr.state);
        },
    }


}