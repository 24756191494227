<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
            <v-btn
                v-show="true"
                color="primary"
                fab
                dark
                medium
                absolute
                bottom
                right
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Create new Session</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            dense
            outlined
            type="error"
            v-if="errorMessage"
          >
            {{ errorMessage }}
          </v-alert>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="session.sessionName"
                  label="Session Name"
                  :error-messages="inputErrorMessage"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error darken-1"
            text
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            color="primary darken-1"
            outlined
            @click="create"
            :loading="creating"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
    import Joi from 'joi';

    const newSessionSchema = Joi.object({
        sessionName: Joi.string().trim().min(1).max(30).pattern(new RegExp('^[a-zA-Z0-9 ]*$')).required(),
    })


    export default {
        data() {
            return {
                dialog: false,
                snackbar: false,
                errorMessage: '',
                inputErrorMessage: "",
                creating: false,
                session: {
                    sessionName: '',
                }
            }
        },

        methods:{
            async create(){
                this.creating = true;


                const body = {
                    sessionName: this.session.sessionName,
                }

                if(!this.validSessionName()){
                  this.creating = false;
                  return;
                }

                //send data to server
                const fetchResult = await fetch(process.env.VUE_APP_BACKEND_SERVER + '/session', {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        'content-type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.token
                    }
                }).then((res) => {

                    //if the request was ok
                    if(res.ok){

                        //convert response to json and send it to next handler
                        return res.json();
                    }

                    //else send new error to catch
                    return res.json().then((error) => {
                        throw new Error(error.message);
                    });

                }).then((res) => {
                      this.creating = false;
                      //update store
                      this.$store.commit('addSession', res.session);
                      this.session.sessionName = '';
                      this.dialog = false;

                }).catch((error) => {
                    //log error 
                    console.error("There was an error",error.message);
                    this.creating = false;

                    if(error.message.includes('limit')){
                      this.errorMessage = error.message;
                    } else if(error.message.includes('fetch')){
                      this.errorMessage = 'There was an error calling the server';
                    }

                    
                })

            },

            close(){
              this.errorMessage = '';
              this.session.sessionName = '';
              this.dialog = false;
            },

            validSessionName(){
                const payload = {
                    sessionName: this.session.sessionName,
                }

                const result = newSessionSchema.validate(payload);

                //if validation return no error
                if(!result.error){
                    //return true
                    return true;

                }

                this.inputErrorMessage = "Only letters, numbers and spaces are allowed. Enter a maximum of 30 characters.";
                return false;


            }
        },
    }
</script>

<style scoped>

</style>