<template>
  <v-app>
    
    <NavDrawer v-model="drawer" v-if="isLoggedIn"></NavDrawer>

    <v-app-bar 
      app
      color="primary"
      dense
    >

      <v-toolbar-title>presenter.tools - Control Portal</v-toolbar-title>
      <v-badge
        content="Alpha"
        value="Alpha"
        color="red"
      ></v-badge>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="isLoggedIn"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-main class="background">
      <v-container 
        class="pa-md-12"
      >
        <v-alert
          v-if="getGlobalMessage"
          :type="getGlobalMessageType"
        >{{ getGlobalMessage }}</v-alert>
        <router-view class="container pt2"/>
      </v-container>
    </v-main>



    <v-footer>

      <small>Copyright &copy; 2021, presenter.tools by Kilian Lentz</small>

    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import NavDrawer from './components/NavDrawer';

export default {
  name: 'App',

  components: {
    NavDrawer,
  },

  data() { 
    return {
      drawer: false,
    } 
  },

  computed: {
    ...mapGetters([
      'isLoggedIn',
      'getGlobalMessage',
      'getGlobalMessageType'
    ])
  },

  async mounted(){
    await this.$store.dispatch('getUserData');
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}
</style>
