<template>
    <v-list 
        dense
        class="background_light"    
    >

        <template
            v-for="(plan, i) in plans"
        >

            <v-list-item 
                :key="i"
                @click="$emit('click', plan.id)"
            >

                <v-list-item-content>
                    {{ plan.planID }}
                </v-list-item-content>

                <v-list-item-action>
                    <v-icon>

                    </v-icon>
                </v-list-item-action>
            </v-list-item>

            <v-divider
                v-if="i < plans.length - 1"
                :key="'a' + i"
            ></v-divider>

        </template>
        

    </v-list>
</template>

<script>
    export default {
        name: "AllPlans",

        props: [
            'plans'
        ]
    }
</script>

<style lang="scss" scoped>

</style>