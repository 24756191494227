<template>
    <v-card
        elevation="2"
        class="mx-auto my-6"
        color="background_light"
        max-width="674"

    >
    
        <v-card-title>Verifing your email address.</v-card-title>

        <v-card-text>
            <v-img
                src="@/assets/loading.gif"
                max-width="100"
                v-if="verifing"
            ></v-img>
            <p
                v-if="message"
            >
                {{ message }}
            </p>
        </v-card-text>

    </v-card>
</template>

<script>
    export default {
        name: "Verify",

        data() {

            return {
                verifing: false,
                message: ""
            }
        },

        props: [
            'code'
        ],

        mounted(){
            this.verifing = true;
            
            fetch(process.env.VUE_APP_BACKEND_SERVER + "/auth/verify/" + this.code)
            .then((res) => {

                this.verifing = false;

                if(res.ok == true){
                    return res.json();
                }

                //if there was an error
                return res.json().then((error) => {

                    //foreward error to catch
                    throw new Error(error.message);
                });

            }).then((res) => {

                this.message = res.message + " You will be automatically redirected to the Login page.";

                setTimeout(() => {

                    //redirect to Dashboard
                    this.$router.push({ name: 'Login' });
                }, 4000)

            }).catch((error) => {
                this.message = error.message;
            })

        }
    }
</script>

<style scoped>
</style>