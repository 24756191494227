export const plans = {

    state: {
        plans: []
    },

    mutations: {
        setPlans(state, plans){
            state.plans = plans;
        }
    },

    getters: {
        getAllPublicPlans(state) {
            return state.plans.filter(x => x.public);
        },
        getPlanById(state){
            for (var i=0; i < state.plans.length; i++) {
                if (state.plans[i].planID === id) {
                    return myArray[i];
                }
            }
        }
    },

    actions: {
        async loadPlans(state){

            const result = await fetch(process.env.VUE_APP_BACKEND_SERVER + '/plan')
            .then((res) => {
                if(res.ok){
                    return res.json();
                }

                return res.json().then((error) => {
                    console.log(error.message);
                    throw new Error(error.message);
                });
            }).then((res) => {
                if(res.plans){
                    this.commit('setPlans', res.plans);
                }
            }).catch((error) => {
                console.log("ERROR", error);

            })

        }
    }

}