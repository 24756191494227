<template>
    <v-card 
        color="background_light"
        class="mx-auto my-6"
        max-width="1000"
    >

        <v-card-title>Change Password</v-card-title>

        <v-form>

            <v-card-text>

                <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="this.errorMessage"
                >
                    {{this.errorMessage}}
                </v-alert>

                <v-alert
                    dense
                    outlined
                    type="success"
                    v-if="this.successMessage"
                >
                    {{this.successMessage}}
                </v-alert>

                <v-text-field
                    v-model="currPW"
                    label="Current Password"
                    color="primary"
                    outlined
                    type="password"
                    cols="6"></v-text-field>

                <v-text-field
                    v-model="newPW"
                    label="New Password"
                    color="primary"
                    outlined
                    type="password"
                    cols="6"></v-text-field>

                <v-text-field
                    v-model="newPWConfirm"
                    label="Confirm new Password"
                    color="primary"
                    outlined
                    type="password"
                    cols="6"></v-text-field>

            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn
                    color="primary"
                    @click="changePW"
                    outlined
                    :loading="changingPW"
                >
                    Change Password    
                </v-btn>

            </v-card-actions>
            
        </v-form>

    </v-card>
</template>

<script>
    export default {
        name: "ChangePassword",

        data() {
            return {
                currPW: "",
                newPW: "",
                newPWConfirm: "",
                changingPW: false,
                errorMessage: "",
                successMessage: ""
            }
        },


        methods: {
            changePW() {

                this.changingPW = true;
                this.errorMessage = "";
                this.successMessage = "";


                if(!this.currPW || !this.newPW || !this.newPWConfirm){
                    this.errorMessage = "All fields are required.";
                    this.changingPW = false;
                    return;
                }

                if(this.newPW != this.newPWConfirm){
                    this.errorMessage = "New Passwords must match.";
                    this.changingPW = false;
                    return;
                }

                const body = {
                    oldPW: this.currPW,
                    newPW: this.newPW
                }
                
                fetch(process.env.VUE_APP_BACKEND_SERVER + '/user/password', {
                    method: 'PATCH',
                    headers: {
                        'authorization': 'Bearer ' + localStorage.token,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }).then((result) => {
                    this.changingPW = false;

                    if(result.ok){
                        return result.json();
                    }

                    return result.json().then((error) => {

                        //foreward error to catch
                        throw new Error(error.message);
                    });

                }).then((result) => {

                    console.log(result);

                    if(result.success){
                        this.successMessage = result.message;
                        return;
                    } else {
                        this.errorMessage = "Unable to change the password.";
                        return;
                    }
                    

                }).catch((error) => {

                    this.errorMessage = error.message;
                    return;


                });
                

            }
        }
    }
</script>

<style scoped>

</style>