<template>
    <section>
        <h1 class="
                mb-md-10
                mb-sm-10
                mb-xs-100"
        >
            Account Settings
        </h1>
        <!-- <v-card 
            color="background_light"
            class="mx-auto my-6"
            max-width="1000"
        >

            <v-card-title>Billing Information</v-card-title>

            <v-form
                @submit.prevent="saveAddress"
            >

                <v-card-text>

                    <v-alert
                        dense
                        outlined
                        type="error"
                        v-if="this.errorMessageBilling"
                    >
                        {{this.errorMessageBilling}}
                    </v-alert>

                    <v-text-field
                        v-model="getUser.fullName"
                        label="Full Name"
                        color="primary"
                        outlined
                    ></v-text-field>

                    <v-text-field
                        v-model="getAddress.line1"
                        label="Street"
                        color="primary"
                        outlined
                    ></v-text-field>

                    <v-text-field
                        v-model="getAddress.postal_code"
                        label="Postal Code"
                        color="primary"
                        outlined
                        cols="6"
                    ></v-text-field>

                    <v-text-field
                        v-model="getAddress.city"
                        label="City"
                        color="primary"
                        outlined
                        cols="6"
                    ></v-text-field>

                    <v-text-field
                        v-model="getAddress.state"
                        label="State"
                        color="primary"
                        outlined
                    ></v-text-field>

                    <v-select
                        v-model="getAddress.country"
                        :items="countries"
                        item-text="country"
                        item-value="code"
                        label="Country"
                        outlined
                    ></v-select>


                </v-card-text>

                <v-card-actions>

                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        @click="saveBilling"
                        outlined
                        :loading="savingBilling"
                    >
                        Save    
                    </v-btn>
                </v-card-actions>

            </v-form>


        </v-card>

        <div class="mb-10"></div> -->

        <ChangePassword></ChangePassword>

        <!-- <div class="mb-10"></div>

        <v-card 
            color="background_light"
            class="mx-auto my-6"
            max-width="1000"
        >

            <v-card-title>Delete Account</v-card-title>

            <v-card-text>
                Entirely remove my account and its data from your servers.
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-dialog
                    v-model="deleteAccountDialog"
                    width="500"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                        >
                            Delete Account
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="headline">
                            Warning
                        </v-card-title>

                        <v-card-text>
                            <v-alert
                                v-if="deleteMessage"
                                type="error"
                            >{{ this.deleteMessage }}</v-alert>

                            Deleting your account will entirely remove all your user data, including the sessions created by you. If you have an active subscription running, you will <b><u>NOT</u></b> receive a refund for the remaining days of the current subscription month.  
                        
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            @click="deleteAccountDialog = false"
                            text
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            outlined
                            @click="deleteAccount"
                            :loading="deleting"
                        >
                            Delete
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-card-actions>

        </v-card> -->

    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import codes from '../assets/countryCodes.js';
    import ChangePassword from '../components/ChangePassword.vue';


    export default {
        data() {
            return {
                errorMessageBilling: "",
                savingBilling: false,
                countries: codes,
                deleteAccountDialog: false,
                deleting: false,
                deleteMessage: "",
            }
        },

        computed: {
            /* user: {
                get() {
                    return this.$store.state.user;
                },
            },
            address: {
                get() {
                    return this.$store.state.address;
                }
            } */
            ...mapGetters([
                'getUser',
                'getAddress'
            ])
        },

        methods: {
            async saveBilling(){
                this.savingBilling = !this.savingBilling;

                const body = {
                    fullName: this.getUser.fullName,
                    address: this.getAddress,
                };

                fetch(process.env.VUE_APP_BACKEND_SERVER + '/user', {
                    method: 'PATCH',
                    body: JSON.stringify(body),
                    headers: {
                        'content-type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.token,
                    },
                }).then((res) => {
                    this.savingBilling = !this.savingBilling;

                    if(res.ok == true){
                        return res.json();
                    }

                    return res.json().then((error) => {

                        //foreward error to catch
                        throw new Error(error.message);
                    });


                }).catch((error) => {
                    this.errorMessageBilling = "There was an error saving your changes.";
                    
                    if(error.message.includes('verified')){
                        this.$store.commit('setGlobalMessage', 'Your account is not veified. Please check your inbox to verify your email address.');
                        this.$store.commit('setGlobalMessageType', 'error');
                    }
                })

                
            },

            async deleteAccount() {
                this.deleting = true;

                await fetch(process.env.VUE_APP_BACKEND_SERVER + '/user', {
                    method: 'DELETE',
                    headers: {
                        'authorization': 'Bearer ' + localStorage.token 
                    }
                }).then((result) => {

                    this.deleting = false;

                    if(result.ok){
                        return result.json();
                    }

                    return result.json().then((error) => {

                        //foreward error to catch
                        throw new Error(error.message);
                    });

                }).then((result) => {

                    this.$store.dispatch("logout");
                    localStorage.removeItem("token");
                    this.$store.commit('setGlobalMessage', 'Your Account has been succesfully deleted.');
                    this.$store.commit('setGlobalMessageType', 'success');
                    this.$router.push("/");

                }).catch((error) => {

                    this.deleting = false;
                    this.deleteAlertType = "error";
                    this.deleteMessage = error;


                });

                



            },
        },

        components: {
            ChangePassword
        }
    }
</script>

<style scoped>

</style>