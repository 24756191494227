import Vue from 'vue';
import Vuex from 'vuex';

import { user } from './modules/user.js';
import { sessions } from './modules/sessions.js';
import { plans } from './modules/plans.js';
import { address } from './modules/address.js';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({

    state: {
        globalMessage: "",
        globalMessageType: "success"
    },

    getters: {
        getGlobalMessage(state){
            return state.globalMessage;
        },
        getGlobalMessageType(state){
            return state.globalMessageType;
        }
    },

    mutations: {
        setGlobalMessage(state, message){
            state.globalMessage = message;
        },
        setGlobalMessageType(state, type){
            state.globalMessageType = type;
        }
    },

    modules: {
        user,
        sessions,
        plans,
        address
    },
    //strict: debug,
})