<template>

    <v-card
        elevation="2"
        class="mx-auto my-6"
        color="background_light"
        max-width="674">

        <v-card-title>Create an Account</v-card-title>

        

        <v-form @submit.prevent="signup">

            <v-card-text>

                <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="this.errorMessage"
                >
                    {{this.errorMessage}}
                </v-alert>

                <v-row>
                    <v-col
                    cols="12"
                    >
                        <v-text-field
                        v-model="user.fullName"
                        label="Full Name/Company"
                        outlined
                        required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    >
                        <v-text-field
                        v-model="user.email"
                        label="E-mail"
                        outlined
                        required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                    >
                        <v-text-field
                            v-model="user.password"
                            label="Password"
                            type="password"
                            outlined
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                    >
                        <v-text-field
                            v-model="user.passwordConfirm"
                            label="Confirm Password"
                            type="password"
                            outlined
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-checkbox
                            v-model="checkTermsPrivacy"
                        >
                            <div slot='label'>I have read and understand <!-- the <a href="https://presenter.tools/terms" target="_blank" @click.stop>Terms fo Service</a> and  -->the <a href="https://presenter.tools/privacy" target="_blank" @click.stop>Privacy Policy</a></div>
                        </v-checkbox>
                    </v-col>
                </v-row>

            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn
                    to="login"
                    color="primary"
                    text
                >Login</v-btn>

                <v-btn
                    v-on:click="signup"
                    color="primary"
                    type="submit"
                    outlined
                    :loading="signingUp"
                >Create Account</v-btn>

            </v-card-actions>

        </v-form>
    </v-card>
</template>

<script>
    import Joi from 'joi';

    

    //validation schema for signup
    const schema = Joi.object({
        email: Joi.string().pattern(new RegExp('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5})$')).required(),
        password: Joi.string().trim().min(8).required(),
        passwordConfirm: Joi.string().trim().min(8).required(),
        fullName: Joi.string().trim().required()
    })

    export default {
        name: 'SignUp',
        data() {
            return {
                user: {
                    fullName: "",
                    email: "",
                    password: "",
                    passwordConfirm: "",
                },
                errorMessage: "",
                checkTermsPrivacy: false,
                signingUp: false,
            }
        },
        methods: {

            /*
            * creates a user object and sends it to the backend
            */
            async signup(event) {

                //reset error message
                this.errorMessage = "";
                this.signingUp = true;

                if(!this.checkTermsPrivacy){
                    /* this.errorMessage = "Please accept the Terms of Service and the Privacy Policy"; */
                    this.errorMessage = "Please accept the Privacy Policy";
                    return;
                }

                //if the entered data is valid
                if(this.validUser()){

                    //create user object
                    const body = {
                        email: this.user.email,
                        password: this.user.password,
                        fullName: this.user.fullName
                    };

                    //send data to server
                    /* const fetchResult = await fetch(process.env.VUE_APP_BACKEND_SERVER + '/auth/signup', { */
                    const fetchResult = await fetch(this.$backend + '/auth/signup', {
                        method: "POST",
                        body: JSON.stringify(body),
                        headers: {
                            'content-type': 'application/json',
                        }
                    }).then((res) => {

                        //if the request was ok
                        if(res.ok){

                            //convert response to json and send it to next handler
                            return res.json();
                        }

                        //else send new error to catch
                        return res.json().then((error) => {
                            throw new Error(error.message);
                        });

                    }).then((res) => {

                        this.signingUp = false;
                        
                        //if user was not created
                        if(!res.created){

                            //set errorMessage to the response message
                            this.errorMessage = res.message;

                        //if created
                        } else {

                            //get token from response and save it into localStorage
                            localStorage.token = res.token;
                            //set store to loggedIn and redirect to the Dashboard
                            this.$store.dispatch('login', [res.user, false]);
                            this.$store.commit('setGlobalMessage', 'Your Account has been succesfully created. Please check your inboy to verify your email address.');
                            this.$store.commit('setGlobalMessageType', 'success');
                            this.$router.push({ name: 'Dashboard', params: { new: true } });
                        }
                    }).catch((error) => {

                        //log error 
                        this.errorMessage = error.message;
                    })
                }
            },

            /*
            * Validates if the submitted user data is processable
            */
            validUser() {

                //if password and confirmPassword dont match
                if(this.user.password != this.user.passwordConfirm){

                    //Set error message and reeturn false
                    this.errorMessage = "Passwords mutch match";
                    return false;
                } 

                //validate the user data
                const result = schema.validate(this.user);

                //if validation return no error
                if(!result.error){

                    //return true
                    return true;
                }

                //if validation error includes the word EMAIL
                if(result.error.message.includes('email')){

                    //set error message
                    this.errorMessage = "Please enter a valid E-Mail adress";

                //else if validation error includes the word fullName
                } else if(result.error.message.includes('fullName')){

                    //set error message
                    this.errorMessage = "Your full Name is required";

                //else error must regard the password
                } else {

                    //Set error message
                    this.errorMessage = "The Password must be at least 8 characters long";
                }

                //after error message is set, return false
                return false;
            }
        }
    }
</script>

<style scoped>
</style>