export const user = {
    state: {
        loggedIn: false,
        fullName: "",
        email: "",
        plan: 0,
        emailStatus: 0,
        accountStatus: 0,
        accountMode: 0,
        stripeID: "",
    },

    getters: {
        getFullName( state ){
            return state.fullName;
        },
        getEmail( state ){
            return state.email;
        },
        isLoggedIn( state ){
            return state.loggedIn;
        },
        getUserPlan(state, plan){
            return state.plan;
        },
        getEmailStatus(state){
            return state.emailStatus;
        },
        getAccountStatus(state){
            return state.accountStatus;
        },
        getAccountMode(state){
            return state.accountMode;
        },
        getStripeID(state){
            return state.stripeID;
        },
        getUser(state){
            return state;
        },
        isAdmin(state){
            if(state.accountMode == 666){
                return true;
            } else {
                return false;
            }
        }
    },

    mutations: {
        setEmail (state, email){
            state.email = email;
        },
        setFullName(state, fullName){
            state.fullName = fullName;
        },
        setLoggedIn(state, loggedIn){
            state.loggedIn = loggedIn;
        },
        setPlan(state, plan){
            state.plan = plan;
        },
        setEmailStatus(state, status){
            state.emailStatus = status;
        },
        setAccountStatus(state, status){
            state.accountStatus = status;
        },
        setAccountMode(state, mode){
            state.accountMode = mode;
        },
        setStripeID(state, id){
            state.stripeID = id;
        },
    },

    actions: {
        async getUserData(state){
            if(localStorage.token){
                const result = await fetch(process.env.VUE_APP_BACKEND_SERVER + '/user', {
                    headers: {
                        'authorization': 'Bearer ' + localStorage.token,
                    }
                }).then((res) => {
                    if(res.ok){
                        return res.json();
                    }

                    return res.json().then((error) => {
                        throw new Error(error.message);
                    });
                }).then((res) => {
                    if(res.user){
                        this.dispatch('login', [res.user, true]);
                    }
                }).catch((error) => {
                    console.log(error);
                    localStorage.removeItem('token');
                })
            }
        },
        logout(state){
            this.commit('setLoggedIn', false);
            this.commit('setEmail', "");
            this.commit('setFullName', "");
            this.commit('setEmailStatus', 0);
            this.commit('setAccountStatus', 0);
            this.commit('setAccountMode', 0);
            this.commit('setPlan', 0);
            this.commit('setStripeID', '');
            this.dispatch('setAddress', {}, {root: true});
        },
        login(state, [user, isAddr]){
            this.commit('setFullName', user.fullName);
            this.commit('setEmail', user.email);
            this.commit('setEmailStatus', user.emailStatus);
            this.commit('setAccountStatus', user.accountStatus);
            this.commit('setAccountMode', user.accountMode);
            this.commit('setPlan', user.plan);
            this.commit('setLoggedIn', true);
            this.commit('setStripeID', user.stripeID);
            if(isAddr) {
                this.dispatch('setAddress', user.address, {root: true});
            }
        },
    }
}
