<template>
    <section>
        <h1 class="
                mb-md-10
                mb-sm-10
                mb-xs-100"
        >
            Dashboard
        </h1>
        <SessionList></SessionList>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex';

    import SessionList from '../components/SessionList';

    export default {
        components:{
            SessionList,
        },

        computed: {
            ...mapGetters([
                'getEmail'
            ])
        }
    }
</script>

<style scoped>
</style>