<template>
    <v-expansion-panel class="background_light">
        <v-expansion-panel-header>
            <template v-slot:default="{ open }">
                <v-row>
                    <v-col
                        xl="11"
                        lg="11"
                        md="10"
                        sm="10"
                        xs="9"
                    >
                        {{ session.sessionName }}
                    </v-col>
                </v-row>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-divider></v-divider>

            <v-row
                class="
                    mb-lg-5
                    mb-md-5
                    mb-sm-5"
            >

                <v-col
                    cols="12"
                    md="4"
                    lg="4"
                >
                    <v-input
                        messages="Send this to link to your speakers"
                        append-icon="mdi-content-copy"
                        v-clipboard:copy="appLink"
                    >
                        {{ appLink }}
                    </v-input>
                </v-col>

                <v-col
                    cols="12"
                    md="4"
                    lg="4"
                >
                    <v-input
                        messages="Session ID"
                        append-icon="mdi-content-copy"
                        v-clipboard:copy="session.sessionID"
                    >
                        {{ session.sessionID }}
                    </v-input>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="4"
                >
                    <v-input
                        messages="Session Password"
                        append-icon="mdi-content-copy"
                        v-clipboard:copy="session.sessionPassword"
                    >
                        {{ session.sessionPassword }}
                    </v-input>
                </v-col>
            </v-row>
            <v-text-field
                color="primary"
                label="Edit Session Name"
                v-model="session.sessionName"
                outlined
                :error-messages="errorMessage"
            ></v-text-field>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-dialog
                    v-model="removeDialog"
                    width="500"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            text
                        >
                            Remove
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="headline">
                            Warning
                        </v-card-title>

                        <v-card-text>
                            You are about to delete the Session
                            <section>
                                {{ session.sessionName }}
                            </section>
                            Are you sure you want to do that?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            @click="removeDialog = false"
                            text
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            outlined
                            @click="remove"
                        >
                            Remove
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                <v-btn
                    color="primary"
                    @click="edit"
                    :loading="saving"
                    outlined
                >
                    Save
                </v-btn>
            </v-card-actions>
            

            
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import Joi from 'joi';

    const newSessionSchema = Joi.object({
        sessionName: Joi.string().trim().min(1).max(30).pattern(new RegExp('^[a-zA-Z0-9 ]*$')).required(),
    })


    export default {
        data() {
            return {
                activator: "",
                open: false,
                removeDialog: false,
                saving: false,
                errorMessage: "",
            }
        },
        props: [
            'session'
        ],

        methods: {
            async edit() {
                this.saving = !this.saving;

                if(!this.validSessionName()) {
                    this.saving = !this.saving;
                    return;
                }


                const body = {
                    sessionID: this.session.sessionID,
                    sessionName: this.session.sessionName
                }

                const result = await fetch(process.env.VUE_APP_BACKEND_SERVER + '/session', {
                    method: 'PATCH',
                    headers: {
                        'content-type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.token,
                    },
                    body: JSON.stringify(body)
                }).then((res) => {
                    if(res.ok){
                        
                        return res.json();
                    }

                    return res.json().then((error) => {
                        throw new Error(error.message);
                    });
                }).then((res) => {
                    if(res.updated){
                        this.$store.commit('setSessionName', this.session.sessionName);
                    }
                }).catch((error) => {

                    //log error 
                    console.error(error);
                })

                this.saving = !this.saving;
            },

            async remove() {
                this.removeDialog = false;
                const result = await this.$store.dispatch('removeSession', this.session).then(() => {
                    this.$emit('click')
                });
            },

            validSessionName(){
                const payload = {
                    sessionName: this.session.sessionName,
                }

                const result = newSessionSchema.validate(payload);

                //if validation return no error
                if(!result.error){
                    console.log("ok");
                    //return true
                    return true;

                }

                console.log("not ok", result.error);
                this.errorMessage = "Only letters, numbers and spaces are allowed. Enter a maximum of 30 characters.";
                return false;


            }
        },

        computed: {
            appLink() {
                return "https://app.presenter.tools?s=" + this.session.sessionID + "&p=" + this.session.sessionPassword;
            }
        }
    }
</script>

<style>
</style>