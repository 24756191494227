<template>
    <v-card
        class="background_light mx-auto my-6"
        max-width="1000"
    >
        
        <v-card-title> 

            Edit {{ user.email }} 

            <v-spacer></v-spacer>

            <v-btn
                text
                color="primary"
                @click="reload"
            >
                <v-icon>
                    mdi-reload
                </v-icon>
            </v-btn>

            <v-btn
                text
                color="primary"
                @click="$emit('close')"
            >
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>

        </v-card-title>

        <v-form>

            <v-card-text>

                <v-divider class="mb-8"></v-divider>

                <h3 class="mb-8">Billing Information</h3>

                <v-text-field
                    v-model="newUser.email"
                    label="Email"
                    outlined
                    color="primary"
                ></v-text-field>

                <v-text-field
                    v-model="newUser.fullName"
                    label="Full Name/Company"
                    outlined
                    color="primary"
                ></v-text-field>

                <v-text-field
                    v-model="newUser.address.line1"
                    label="Street"
                    outlined
                    color="primary"
                ></v-text-field>

                <v-text-field
                    v-model="newUser.address.postal_code"
                    label="Postal Code"
                    outlined
                    color="primary"
                ></v-text-field>

                <v-text-field
                    v-model="newUser.address.city"
                    label="City"
                    outlined
                    color="primary"
                ></v-text-field>

                <v-text-field
                    v-model="newUser.address.state"
                    label="State"
                    outlined
                    color="primary"
                ></v-text-field>

                <v-select
                    v-model="newUser.address.country"
                    :items="countries"
                    item-text="country"
                    item-value="code"
                    label="Country"
                    outlined
                ></v-select>

                <v-divider class="mb-5"></v-divider>

                <h3 class="mb-8">Account Information</h3>

                <v-select
                    v-model="newUser.plan"
                    :items="plans"
                    item-text="name"
                    item-value="code"
                    label="Plan"
                    outlined
                ></v-select>

                <v-select
                    v-model="newUser.accountStatus"
                    :items="accountStat"
                    item-text="name"
                    item-value="code"
                    label="Account Status"
                    outlined
                ></v-select>

                <v-select
                    v-model="newUser.accountMode"
                    :items="accountModes"
                    item-text="name"
                    item-value="code"
                    label="Account Mode"
                    outlined
                ></v-select>

                <v-divider class="mb-5"></v-divider>

                <v-checkbox
                    v-model="checkUpdate"
                    label="I'm sure I want to update this user's information."
                ></v-checkbox>

            </v-card-text>

            <v-card-actions>

                <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="this.errorMessage"
                >
                    {{this.errorMessage}}
                </v-alert>

                <v-alert
                    dense
                    outlined
                    type="success"
                    v-if="this.updated"
                >
                    The User's information has been successfully updated.
                </v-alert>

                <v-spacer></v-spacer>

                <v-btn
                    outlined
                    color="primary"
                    @click="save"
                >
                    Save
                </v-btn>

            </v-card-actions>

        </v-form>


    </v-card>
</template>

<script>
    import codes from '../assets/countryCodes.js';

    export default {
        name: "User",

        data() {
            return {
                newUser: {
                    email: "",
                    fullName: "",
                    plan: 0,
                    accountStatus: 0,
                    accountMode: 0,
                    address: {
                        line1: "",
                        city: "",
                        postal_code: "",
                        state: "",
                        country: ""
                    }
                },
                countries: codes,
                plans: [
                    {
                        name: "Free",
                        code: 1
                    },
                    {
                        name: "Basic",
                        code: 2
                    },
                    {
                        name: "Pro",
                        code: 3
                    }
                ],
                accountStat: [
                    {
                        name: "Blocked",
                        code: 0
                    },
                    {
                        name: "Not Verified",
                        code: 1
                    },
                    {
                        name: "Active",
                        code: 2
                    }
                ],
                accountModes: [
                    {
                        name: "User",
                        code: 0
                    },
                    {
                        name: "Admin",
                        code: 666
                    }
                ],
                checkUpdate: false,
                errorMessage: "",
                updated: false,
            }
        },

        props: [
            'user'
        ],

        mounted() {
            this.newUser = Object.assign({}, this.user);
        },

        methods: {
            reload(){
                this.newUser = Object.assign({}, this.user);
            },

            /*
            * Sends the new user data to the server
            */
            async save(){
                this.errorMessage = "";

                if(!this.checkUpdate){
                    this.errorMessage = "Please check the checkbox."
                    return;
                }

                const body = {
                    email: this.user.email,
                    data: this.newUser,
                }

                await fetch(process.env.VUE_APP_BACKEND_SERVER + '/admin/user', {
                    method: 'PATCH',
                    body: JSON.stringify(body),
                    headers: {
                        'content-type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.token,
                    },
                }).then((res) => {
                    if(res.ok){
                        return res.json();
                    }

                    return res.json().then((error) => {

                        //foreward error to catch
                        throw new Error(error.message);
                    });
                }).then((res) => {
                    this.updated = true;
                }).catch((error) => {
                    this.errorMessage = error.message;
                })

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>