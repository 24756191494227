<template>
    <v-card>

        <v-toolbar class="
                        mb-md-10
                        mb-xs-100
                        toolbar
        ">
            <v-toolbar-title>All Sessions</v-toolbar-title>
            
        
            <template v-slot:extension>
                <NewSessionDialog></NewSessionDialog>
            </template>

        </v-toolbar>
        
        <v-expansion-panels>
            <SessionListItem v-for="(session, index) in getAllSessions" :key="index" :session="session" @click="updateList"></SessionListItem>
        </v-expansion-panels>

    </v-card>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import SessionListItem from './SessionListItem';
    import NewSessionDialog from './NewSessionDialog';

    export default {
        components: {
            SessionListItem,
            NewSessionDialog,
        },

        data() {
            return {
                activator: "",
            }
        },

        computed: {
            ...mapGetters([
                'getAllSessions',
            ])
        },

        methods: {

            updateList(){
                this.$store.dispatch('getSessions');
            }
        },

        async mounted(){
            await this.$store.dispatch('getSessions');
        }


    }
</script>

<style scoped>
.toolbar {
    margin-bottom: 3em;
}
</style>