export const sessions = {

    state: {
        sessions: []
    },

    getters: {
        getAllSessions(state){
            return state.sessions;
        },
        getSession(state, sessionID) {
            for (var i=0; i < state.sessions.length; i++) {
                if (state.sessions[i].sessionID === sessionID) {
                    return myArray[i];
                }
            }
        }
    },

    mutations: {
        addSession(state, session){
            state.sessions.push(session);
        },
        setSessions(state, sessions){
            state.sessions = sessions;
        },
        setSessionName(state, session, name){
            const curSession = {};

            for (var i=0; i < state.sessions.length; i++) {
                if (state.sessions[i].sessionID === session.sessionID) {
                    curSession = myArray[i];
                }
            }

            curSession.sessionName = name;
        }
    },

    actions: {
        /*
        * fetches all sessions a user has created
        */
        async getSessions(state, user){

            if(localStorage.token){
                //fetch sessions
                const result = await fetch(process.env.VUE_APP_BACKEND_SERVER + '/session/all', {
                    headers: {
                        'authorization': 'Bearer ' + localStorage.token,
                    }
                }).then((res) => {
                    if(res.ok){
                        return res.json();
                    }

                    return res.json().then((error) => {
                        throw new Error(error.message);
                    });
                }).then((res) => {

                    if(res.sessions){
                        this.commit('setSessions', res.sessions);
                    }
                }).catch((error) => {

                    if(error.message.includes('verified')){
                        this.commit('setGlobalMessage', 'Your account is not veified. Please check your inbox to verify your email address.');
                        this.commit('setGlobalMessageType', 'error');
                        console.log("NOT VERIFIED");
                    }

                    console.log("ERROR", error);
                })
            }
            
        },

        async removeSession(state, session){

            const body = {
                sessionID: session.sessionID
            }

            const result = await fetch(process.env.VUE_APP_BACKEND_SERVER + '/session', {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.token,
                },
                body: JSON.stringify(body)
            }).then((res) => {

                if(res.ok){
                    return res.json();
                }

                return res.json().then((error) => {
                    throw new Error(error.message);
                });
            }).then((res) => {
                if(res.deleted){
                    return true;
                } else {
                    return false;
                }
            }).catch((error) => {

                //log error 
                console.error(error);
            })

        }

    }

}