<template>
    <section>
        <h1 class="
                mb-md-10
                mb-sm-10
                mb-xs-100"
        >Manage Subscription</h1>
        <v-alert
            color="primary"
            type="info"
            outlined
        >During the ALPHA Phase, every user will be assigned to the BASIC plan for FREE!</v-alert>
        <SubList @click="selectPlan"></SubList>
        <v-row>
            <v-col>
                
                <v-card
                    color="primary"
                    disabled
                    v-if="show"
                >
                    <v-card-title>Checkout</v-card-title>
                    <v-card-text>
                        <v-divider></v-divider>
                        <table>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Selecetd Plan
                                    </th>
                                    <th class="text-left">
                                        Sessions
                                    </th>
                                    <th class="text-left">
                                        Clients
                                    </th>
                                    <th class="text-left">
                                        Price per month
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <!-- <td>{{ getPlanById(selectedPlan) }}</td>
                                    <td>{{ getPlanById(selectedPlan).maxSessions }}</td>
                                    <td>{{ getPlanById(selectedPlan).maxClients }}</td>
                                    <td>{{ getPlanById(selectedPlan).monthlyPrice / 100 }}</td> -->
                                </tr>
                        </tbody>
                        </table>
                        <v-divider></v-divider>
                        <section>
                            
                        </section>
                            <!-- <v-stripe-card
                                v-model="cardSource"
                                :api-key="apiKey"
                                create="source"
                            /> -->
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
    import SubList from '../components/SubList';


    export default {
        data() {
            return {
                apiKey: process.env.VUE_APP_STRIPE_API_KEY,
                cardSource: null,
                show: false,
            }
        },
        components: {
            SubList
        },
        methods: {
            selectPlan(id){
                console.log("selected", id);
            }
        },

        async mounted(){
            await this.$store.dispatch('loadPlans');
        }
    }
</script>

<style scoped>

</style>