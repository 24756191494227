import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index';

import Home from '../views/Home.vue'
import SignUp from '../views/SignUp';
import Login from '../views/Login';
import Dashboard from '../views/Dashboard';
import Account from '../views/Account';
import Subscription from '../views/Subscription';
import UserManagement from '../views/admin/UserManagement';
import PlanManagement from '../views/admin/PlanManagement';
import Verify from '../views/Verify.vue';

Vue.use(VueRouter)

//checks if user is logged in. if not forewards to auth route
function redirectLoggedInDashboard(to, from, next){
  if(localStorage.token){
    next('/dashboard');
  } else {
    next();
  }
}

function redirectNotLoggedInLogin(to, from, next){
  if(localStorage.token){
    next();
  } else {
    next('/login');
  }
}

function redirectDashboardNotAdmin(to, from, next){
  if(store.getters.isAdmin){
    next();
  } else {
    next('/dashboard');
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  /* {
    path: '/signup',
    name: 'SignUp',
    beforeEnter: redirectLoggedInDashboard,
    component: SignUp
  }, */
  {
    path: '/login',
    name: 'Login',
    beforeEnter: redirectLoggedInDashboard,
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter: redirectNotLoggedInLogin,
    component: Dashboard
  },
  {
    path: '/account',
    name: 'Account',
    beforeEnter: redirectNotLoggedInLogin,
    component: Account
  },
  /* {
    path: '/subscription',
    name: 'Subscription',
    beforeEnter: redirectNotLoggedInLogin,
    component: Subscription
  }, */
  /* {
    path: '/user',
    name: 'UserManagement',
    beforeEnter: redirectDashboardNotAdmin,
    component: UserManagement,
  }, */
  /* {
    path: '/plans',
    name: 'PlanManagement',
    beforeEnter: redirectDashboardNotAdmin,
    component: PlanManagement,
  }, */
  /* {
    path: "/verify/:code",
    name: "Verify",
    component: Verify,
    props: true
  } */
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('setGlobalMessage', '');
  store.commit('setGlobalMessageType', 'success');
  next();
})



export default router
