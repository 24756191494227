<template>
    <v-card
        class="background_light mx-auto my-6"
        max-width="1000"
    >

        <v-card-title>All Users</v-card-title>

        <v-list 
            dense
            class="background_light"    
        >

            <template
                v-for="(user, i) in users"
            >

                <v-list-item 
                    :key="i"
                    @click="$emit('click', user.email)"
                >

                    <v-list-item-content>
                        {{ user.email }}
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-icon>

                        </v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider
                    v-if="i < users.length - 1"
                    :key="'a' + i"
                ></v-divider>

            </template>
            

        </v-list>

    </v-card>
</template>

<script>
    export default {
        name: "AllUsers",

        props: [
            'users'
        ]
    }
</script>

<style lang="scss" scoped>

</style>