<template>
    <v-card
      elevation="2"
      class="mx-auto my-6"
      color="background_light"
      max-width="674"
    >

      <v-card-title>
        Welcome to the RemotePresenter Admin Portal
      </v-card-title>
      <v-card-text>Summary of Portal</v-card-text>
      <v-card-actions>
        <!-- <v-btn to="/signup" outlined color="primary">Sign Up</v-btn> -->
        <v-btn to="/login" outlined color="primary">Login</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>

export default {
  name: 'Home',
}
</script>
