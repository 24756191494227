import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);


export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#FFBA00',
                secondary: '#2196f3',
                accent: '#00bcd4',
                error: '#f44336',
                warning: '#ff5722',
                info: '#cddc39',
                success: '#4caf50',
                background: '#1E1E1E',
                background_light: '#272727'
            }
        }
        
    },
    icons: {
        iconfont: 'fa',
    },
});
